/*
      TODO: NEW THEME

      1. open ../styles/app.scss
      2. define new theme as class
         sample: body.themename {}
      3. import theme scss file in new class:
         sampple: @import '../theme/themename';

      ============
      result:

        body.themename {
          @import '../theme/themename';
        }

      ============
*/

// ======================= LOAD GLOBAL ELEMENTS =========================== //

@import '../styles/modules/all';

// =========================== CUSTOM STYLES ============================== //


/*
  Posible variable can be found in following SCSS Files:

  ../styles/modules/_colors.scss
  ../styles/modules/_fonts.scss
  ../styles/modules/_variables.scss
 */

.logo,
.product-logo{
  background-image: url("/assets/images/theme/euromaster.png");
}

.top-bar {
  .fa-caret-down {
    top: 0!important;
  }
}


$highlight-color: #005e2c;
$car-selection-background: url('/assets/images/global/background_auth.jpg') no-repeat center center fixed;

// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
$fallback: sans-serif;
$main-font: 'Roboto', $fallback;
$condensed-font: 'Roboto', $fallback;
$font-size: 13.5px;
$font-weight: 400;



.top-bar {
  background-image: linear-gradient(to bottom, #154194 0%, #0080c9 100%) !important;
  background-repeat: repeat-x !important;
}



// ================================ LAST ELEMENTS ========================== //

/*               MUST BE LOADED IN EVERY THEME AFTER OVERWRITES              */

// ======================================================================== //


@import 'includes';
